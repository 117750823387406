<template>
  <div>
    <div class="top" style="margin-bottom: 10px">
      <el-date-picker format="yyyy" value-format="yyyy" size="small" v-model="year" type="year" placeholder="选择年" class="mr10"> </el-date-picker>
      <el-input v-model="period" size="small" style="width: 200px" class="mr10">></el-input>
      <el-button v-if="waybillSearchFlag" class="mr10" type="primary" size="small" @click="getTableData">查询</el-button>
      <el-upload
        action
        :on-change="fileChange"
        :show-file-list="false"
        :limit="1"
        :headers="headers"
        :file-list="fileList"
        :http-request="importHandle"
        style="display: inline-block"
        class="mr10"
      >
        <el-button class="mr10" size="small" v-if="exportFlag" type="primary">导入</el-button>
      </el-upload>
    </div>
    <div class="title">
      <span class="year">{{ year }}</span> 第三方物流平台周报数据报表({{ period }})
    </div>
    <el-table
      :row-style="{ height: '40px' }"
      :cell-style="columnStyle"
      :header-cell-style="{ background: '#3f608e', color: '#fff', height: '40px' }"
      :span-method="mergeCell"
      :data="tableData"
      style="width: 100%"
    >
      <template v-for="(item, index) in tableColumn">
        <el-table-column v-if="item.children" width="180" align="center" :index="item.id" :key="index" :prop="item.prop" :label="item.name">
          <template v-for="(item_child, index1) in item.children">
            <el-table-column v-if="item_child.children" :index="item_child.id" :key="index1"></el-table-column>
            <el-table-column
              v-else
              :label="item_child.name"
              :index="item_child.id"
              :key="index1"
              align="center"
              :type="item_child.type"
              :prop="item_child.prop"
            ></el-table-column>
          </template>
        </el-table-column>
        <el-table-column v-else :label="item.name" :index="item.id" :key="index" align="center" :type="item.type" :prop="item.prop">
        </el-table-column>
      </template>
    </el-table>
    <el-table
      :row-style="{ height: '40px' }"
      :cell-style="columnStyle1"
      :header-cell-style="{ background: '#9ab3d4', height: '40px', color: '#fff' }"
      :span-method="mergeCell"
      :data="tableData1"
      style="width: 100%"
    >
      <template v-for="(item, index) in tableColumn">
        <el-table-column v-if="item.children" width="180" align="center" :index="item.id" :key="index" :prop="item.prop" :label="item.name">
          <template v-for="(item_child, index1) in item.children">
            <el-table-column v-if="item_child.children" :index="item_child.id" :key="index1"></el-table-column>
            <el-table-column
              v-else
              :label="item_child.name"
              :index="item_child.id"
              :key="index1"
              align="center"
              :type="item_child.type"
              :prop="item_child.prop"
            ></el-table-column>
          </template>
        </el-table-column>
        <el-table-column v-else :label="item.name" :index="item.id" :key="index" align="center" :type="item.type" :prop="item.prop">
        </el-table-column>
      </template>
    </el-table>
    <el-table
      :row-style="{ height: '40px' }"
      :header-cell-style="{ background: '#9ab3d4', height: '40px', color: '#fff' }"
      :cell-style="columnStyle1"
      :span-method="mergeCell"
      :data="tableData2"
      style="width: 100%"
    >
      <template v-for="(item, index) in tableColumn">
        <el-table-column v-if="item.children" width="180" align="center" :index="item.id" :key="index" :prop="item.prop" :label="item.name">
          <template v-for="(item_child, index1) in item.children">
            <el-table-column v-if="item_child.children" :index="item_child.id" :key="index1"></el-table-column>
            <el-table-column
              v-else
              :label="item_child.name"
              :index="item_child.id"
              :key="index1"
              align="center"
              :type="item_child.type"
              :prop="item_child.prop"
            ></el-table-column>
          </template>
        </el-table-column>
        <el-table-column v-else :label="item.name" :index="item.id" :key="index" align="center" :type="item.type" :prop="item.prop">
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>

<script>
import $ from 'jquery'

import axios from 'axios'
const bud = ['单量', '营业额', '收益', '入驻车辆', '车辆渗透率', '配送线路', '用户数量', '用户渗透率', '运营城市']
const bud1 = [
  '单量',
  '营业额',
  '收益',
  // "入驻车辆",
  // "车辆渗透率",
  // "配送线路",
  '用户数量',
  '用户渗透率',
  '运营城市',
]
const prop1 = [
  'orders',
  'turnover',
  'income',
  // "cars",
  // "carPermeability",
  // "line",
  'users',
  'userPermeability',
  'city',
]
const prop = ['orders', 'turnover', 'income', 'cars', 'carPermeability', 'line', 'users', 'userPermeability', 'city']
export default {
  name: 'table',
  data() {
    return {
      waybillSearchFlag: true,
      exportFlag: true,
      year: '2024',
      period: '',
      headers: { 'Content-Type': 'multipart/form-data' },
      fileList: [],
      action: `${this.$apiPath}/api/urban/otherPlatform/importWeeklyReport`,
      tableColumn: [
        {
          id: 8,
          name: '',
          prop: 'test',
          type: '',
        },
        {
          id: 2,
          name: '指标项',
          prop: 'name',
          type: '',
        },
        {
          id: 3,
          name: '周新增',
          prop: '',
          type: '',
          children: [
            {
              id: 31,
              name: '经销商',
              prop: 'mock',
              type: '',
            },
            {
              id: 31,
              name: '低压总部+分拨中心',
              prop: 'mock1',
              type: '',
            },
            {
              id: 31,
              name: '平台运营',
              prop: 'mock2',
              type: '',
            },
            {
              id: 31,
              name: '合计',
              prop: 'mock3',
              type: '',
            },
          ],
        },
        {
          id: 4,
          name: '累计',
          prop: '',
          children: [
            {
              id: 41,
              name: '经销商',
              prop: 'count',
              type: '',
            },
            {
              id: 41,
              name: '低压总部+分拨中心',
              prop: 'count1',
              type: '',
            },
            {
              id: 41,
              name: '平台运营',
              prop: 'count2',
              type: '',
            },
            {
              id: 41,
              name: '合计',
              prop: 'count3',
              type: '',
            },
          ],
        },
      ],
      tableData: [],
      tableData1: [],
      tableData2: [],
    }
  },
  methods: {
    fileChange(file, fileList) {
      this.fileList.push(file.raw)
    },
    //  handleExceed(files, fileList) {
    //    this.$message({
    //      type: 'warning',
    //      message: '当前限制选择 1 个文件',
    //    })
    //  },
    columnStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return 'background:	#4175b0;color:#000'
      }
      if (columnIndex == 1) {
        return 'background:	#d7dce3;color:#000'
      }
      if ([2, 3, 6, 7].includes(columnIndex)) {
        return 'background:	#dfebf6;color:#000'
      }
      if ([4, 8].includes(columnIndex)) {
        return 'background:	#c2d7ec;color:#000'
      }
      if ([5, 9].includes(columnIndex)) {
        return 'background:	#a2c1e3;color:#000'
      }
    },
    columnStyle1({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 0) {
        return 'background:	#a2c1e3;color:#000'
      }
      if (columnIndex == 1) {
        return 'background:	#d7dce3;color:#000'
      }
      if ([2, 3, 6, 7].includes(columnIndex)) {
        return 'background:	#dfebf6;color:#000'
      }
      if ([4, 8].includes(columnIndex)) {
        return 'background:	#c2d7ec;color:#000'
      }
      if ([5, 9].includes(columnIndex)) {
        return 'background:	#a2c1e3;color:#000'
      }
    },
    importHandle(item) {
      const form = new FormData()
      const fileObj = item.file
      form.append('importFile', fileObj)
      const url = `${this.$apiPath}/api/urban/otherPlatform/importWeeklyReport` //上传地址
      axios({
        url,
        method: 'post',
        data: form,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(res => {
        if (res.data.success) {
          //  this.year = ''
          this.period = ''
          this.fileList = []
          this.$message({
            type: 'success',
            message: res.data.msg,
          })
        } else {
          this.fileList = []
          this.$message({
            type: 'error',
            message: res.data.msg,
          })
        }
        console.log(res)
      })
    },
    mergeCell({ row, column, rowIndex, columnIndex }) {
      let spanOneArr = []
      let concatOne = 0
      this.tableData.map((item, index) => {
        if (index === 0) {
          spanOneArr.push(1)
        } else {
          //第一列需合并相同内容的判断条件
          if (item.test === this.tableData[index - 1].test) {
            spanOneArr[concatOne] += 1
            spanOneArr.push(0)
          } else {
            spanOneArr.push(1)
            concatOne = index
          }
        }
      })
      // 需要合并的列号 第1列 不可改
      if (columnIndex === 0) {
        const _row = spanOneArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col,
        }
      }
    },
    buildData(res) {
      console.log(11)
      let test = res.filter(e => e.mode == 1 && e.type == 1 && e.subject == 1)
      let test1 = res.filter(e => e.mode == 1 && e.type == 1 && e.subject == 2)
      let test2 = res.filter(e => e.mode == 1 && e.type == 1 && e.subject == 3)
      let test3 = res.filter(e => e.mode == 1 && e.type == 1 && e.subject == 4)
      let test4 = res.filter(e => e.mode == 1 && e.type == 2 && e.subject == 1)
      let test5 = res.filter(e => e.mode == 1 && e.type == 2 && e.subject == 2)
      let test6 = res.filter(e => e.mode == 1 && e.type == 2 && e.subject == 3)
      let test7 = res.filter(e => e.mode == 1 && e.type == 2 && e.subject == 4)
      prop.forEach(e => {
        let obj = {
          mock: test[0][e] || '/',
          mock1: test1[0][e] || '/',
          mock2: test2[0][e] || '/',
          mock3: test3[0][e] || '/',
          count: test4[0][e] || '/',
          count1: test5[0][e] || '/',
          count2: test6[0][e] || '/',
          count3: test7[0][e] || '/',
        }
        this.tableData.push(obj)
      })
      this.tableData.forEach((e, i) => {
        e.name = bud[i]
        e.test = '平台汇总'
      })
    },
    buildData1(res) {
      let test = res.filter(e => e.mode == 2 && e.type == 1 && e.subject == 1)
      let test1 = res.filter(e => e.mode == 2 && e.type == 1 && e.subject == 2)
      let test2 = res.filter(e => e.mode == 2 && e.type == 1 && e.subject == 3)
      let test3 = res.filter(e => e.mode == 2 && e.type == 1 && e.subject == 4)
      let test4 = res.filter(e => e.mode == 2 && e.type == 2 && e.subject == 1)
      let test5 = res.filter(e => e.mode == 2 && e.type == 2 && e.subject == 2)
      let test6 = res.filter(e => e.mode == 2 && e.type == 2 && e.subject == 3)
      let test7 = res.filter(e => e.mode == 2 && e.type == 2 && e.subject == 4)
      prop1.forEach(e => {
        let obj = {
          mock: test[0][e] || '/',
          mock1: test1[0][e] || '/',
          mock2: test2[0][e] || '/',
          mock3: test3[0][e] || '/',
          count: test4[0][e] || '/',
          count1: test5[0][e] || '/',
          count2: test6[0][e] || '/',
          count3: test7[0][e] || '/',
        }
        this.tableData1.push(obj)
      })
      this.tableData1.forEach((e, i) => {
        e.name = bud1[i]
        e.test = '运力补充'
      })
    },
    buildData2(res) {
      let test = res.filter(e => e.mode == 3 && e.type == 1 && e.subject == 1)
      let test1 = res.filter(e => e.mode == 3 && e.type == 1 && e.subject == 2)
      let test2 = res.filter(e => e.mode == 3 && e.type == 1 && e.subject == 3)
      let test3 = res.filter(e => e.mode == 3 && e.type == 1 && e.subject == 4)
      let test4 = res.filter(e => e.mode == 3 && e.type == 2 && e.subject == 1)
      let test5 = res.filter(e => e.mode == 3 && e.type == 2 && e.subject == 2)
      let test6 = res.filter(e => e.mode == 3 && e.type == 2 && e.subject == 3)
      let test7 = res.filter(e => e.mode == 3 && e.type == 2 && e.subject == 4)
      prop.forEach(e => {
        let obj = {
          mock: test[0][e] || '/',
          mock1: '/',
          mock2: test2[0][e] || '/',
          mock3: test3[0][e] || '/',
          count: test4[0][e] || '/',
          count1: '/',
          count2: test6[0][e] || '/',
          count3: test7[0][e] || '/',
        }
        this.tableData2.push(obj)
      })
      this.tableData2.forEach((e, i) => {
        e.name = bud[i]
        e.test = '闲时接单'
      })
      console.log(this.tableData2)
    },
    getTableData() {
      this.tableData = []
      this.tableData1 = []
      this.tableData2 = []
      let params = {
        year: this.year,
        period: this.period,
      }
      axios({
        method: 'post',
        //   url: 'http://api-t.zjtyd.com/api/urban/otherPlatform/queryWeeklyReport',
        url: `${this.$apiPath}/api/urban/otherPlatform/queryWeeklyReport`,
        data: params,
      }).then(data => {
        if (data.data.success) {
          this.$message({
            type: 'success',
            message: data.data.msg,
          })
          let res = data.data.data || []
          this.year = res[0].year
          this.period = res[0].period
          this.buildData(res)
          this.buildData1(res)
          this.buildData2(res)
        } else {
          this.$message({
            type: 'error',
            message: data.data.msg,
          })
        }
      })
    },
    getTableData1() {},
  },
  mounted() {
    //  this.getTableData()
    //  this.getTableData1()
    let parentId = '2_4_3'
    let currentPageButtons = arrayQuery.text(parentId)
    let _this = this
    _this.currentPageButtons = currentPageButtons
    //查询按钮权限
    let exportF = currentPageButtons.find(item => {
      return item.menuId == '2_4_3_1'
    })
    if (exportF == undefined) {
      _this.waybillSearchFlag = false
    } else {
      _this.waybillSearchFlag = true
    }
    //导入按钮权限
    let exportFf = currentPageButtons.find(item => {
      return item.menuId == '2_4_3_2'
    })
    if (exportFf == undefined) {
      _this.exportFlag = false
    } else {
      _this.exportFlag = true
    }
  },
}
</script>

<style lang="scss">
.title {
  text-align: center;
  height: 40px;
  line-height: 40px;
  width: 100%;
  background: #2b4e75;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
}
.top {
  display: flex;
  justify-content: flex-end;
}
.mr10 {
  margin-right: 10px;
}
.year {
  text-align: left;
  margin-left: 20px;
}
</style>
