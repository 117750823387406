<!--
 * @Descripttion: ----描述----
 * @version: 1.
 * @Author: 张静
 * @Date: 2024-01-23 14:48:59
 * @LastEditors: 张静
 * @LastEditTime: 2024-01-30 16:16:45
-->
<template>
  <div class="ces-main" style="height: 100%">
    <div class="boxbutton">
      <el-row>
        <!-- <el-col :span="16">
          <div class="grid-content bg-purple">
            <el-tooltip class="item" effect="dark" content="提示：请先选择查询条件再进行导出." placement="bottom">
              <el-button type="primary" plain size="mini" v-if="exportFlag" @click="plannedWaybillExport">导入</el-button>
            </el-tooltip>
          </div>
        </el-col> -->
          <div class="grid-content bg-purple">
           <other-Table></other-Table>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>


<script>
    //引入组件
    import SearchForm from '../../components/common/form.vue'
    import cesTable from '../../components/common/table.vue'
    import Drawer from '../../components/common/drawer.vue'
    import arrayQuery from '../../components/arrayQuery.js'
    import axios from 'axios'
    import qs from 'qs'
    import 'element-ui/lib/theme-chalk/index.css'
    import flightTable from "../../components/common/flightTable.vue"
    import otherTable from "../../components/common/otherTable.vue"
    export default {
        data(){
            let status=[{label:'初始',value:'0'},{label:'已确认',value:'2'},{label:'已撤单',value:'4'}]
            let statusProps={label:'label',value:'value'}
            return {
                visible: false,
                editFlag:false,
                saveFlag:false,
                table: false,
                dialog: false,
                loading: false,
                dialogVisible_orderNo:'',
                dialogImageUrl_via:'',
                saveAndAddFlag:false,
                dialog_waybillManagementReceiptUpload:false,
                hideUp:false,
                dialogImageUrl:'',
                dialogVisible_saveUpload : false,
                showBtnImg:true,
                noneBtnImg:false,
                dialogVisible_JustShow:false,
                limitCountImg:1,
                tableDatadialog: [],
                materialTableData:[],
                materialTableData_JustShow:[],
                exportFlag:true,
              rulesForm: {
                AccurateAndUnique_orderNo:'',

              },
                columns: [
                    {
                        id: "selection",
                        type: "selection",
                        label: "",
                        fixed: "left",
                        width: "55",
                        prop: "",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "carriersId",
                        type: "",
                        label: "编码",
                        fixed: false,
                        width: "280",
                        prop: "carriersId",
                        isShow: true,
                        align: "center"
                    },
                    {
                        id: "carriersName",
                        type: "",
                        label: "名称",
                        fixed: false,
                        width: "280",
                        prop: "carriersName",
                        isShow: true,
                        align: "center"
                    }
                ],
                tableDatadialog_pagination:{
                    pageSize:5,
                    pageNum:1,
                    total:0
                },
                pagesizes: [5, 10, 15, 20],
                waybillDoFormrules: {

                    consignId: [
                        { required: true, message: '请选择发货方', trigger: ['blur', 'change'] }
                    ],
                    consignName: [
                        { required: true, message: '请输入发货方名称', trigger: 'blur' }
                    ],
                    consignAddress: [
                        { required: true, message: '发货方地址必填', trigger: 'blur' }
                    ],
                    consignLinkman: [
                        { required: true, message: '发货方联系人必填', trigger: 'blur' }
                    ],
                    consignMobile:[
                        { required: true, message: "发货方手机号必填", trigger: "blur" },
                        { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
                        {
                            pattern: '^1[345789]\\d{9}$',
                            message: "请输入正确的手机号码",
                            trigger: "blur"
                        }
                    ],
                    vendeeName: [
                        { required: true, message: '请输入收货方名称', trigger: 'blur' }
                    ],
                    vendeeAddress: [
                        { required: true, message: '收货方地址必填', trigger: 'blur' }
                    ],
                    vendeeLinkman: [
                        { required: true, message: '收货方联系人必填', trigger: 'blur' }
                    ],
                    vendeeMobile:[
                        { required: true, message: "收货方手机号必填", trigger: "blur" },
                        { min: 11, max: 11, message: "请输入11位手机号码", trigger: "blur" },
                        {
                            pattern: '^1[345789]\\d{9}$',
                            message: "请输入正确的手机号码",
                            trigger: "blur"
                        }
                    ],
                    requiredDeliveryTime:[
                        { required: true, message: '要求到货时间必填', trigger: 'blur' }
                    ]
                },
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < Date.now() - 24 * 60 * 60 * 1000
                    }
                },
                form: {
                    name: '',
                    region: '',
                    date1: '',
                    date2: '',
                    delivery: false,
                    type: [],
                    resource: '',
                    desc: ''
                },
                saveReceipt:{
                    orderNo:'',
                    orderReceiptUrl:''
                },
                imgurl_s:'',
                imgflag:true,
                imgflagmodel:false,
                srcList: [],
                dialogImageUrl_via_srcList:[],
                imgflagdel:false,
                AccurateAndUnique_batchNo:'',
                dataForms:{
                    batchNo:'',
                    truckNo:'',
                    driverId:'',
                    driverName:'',
                    startTime:'',
                    endtime:'',
                  abnormal:'',
                  abnormalResult:'',
                  abnormalLevel:'',
                  orderNo:"",
                  timeDefault:'',
                  timeDefault2:'',
                  createTimeBegin:'',
                  createTimeEnd:''
                },
                waybillDoForm:{
                    orderNo:'',
                    consignId:'',
                    consignName:'',
                    consignAddress:'',
                    consignLinkman:'',
                    consignPhone:'',
                    consignMobile:'',
                    vendeeName:'',
                    vendeeAddress:'',
                    vendeeLinkman:'',
                    vendeePhone:'',
                    vendeeMobile:'',
                    replyDeliveryTime:'',
                    requiredDeliveryTime:'',
                    pkgQty:'',
                    weight:'',
                    volume:'',
                    wunit:'',
                    vunit:'',
                    vendeeLine:''
                },
                formLabelWidth: '80px',
                timer: null,
                searchData:{ //查询表单的对应的值
                    orderNo:''
                },
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableCols:[ //表格列数据
                    //{label:'运单编号',prop:'orderNo',width:'160',align:'center'},
                    {label:'批次号',type: "imgLink",prop:'batchNo',orderNo:'orderNo',align:'center',width:'180'},
                    {label:'车牌',type: "imgLinkProNoder",prop:'truckNo',align:'center',width:'120'},
                    {label:'司机ID',prop:'driverId',align:'center'},
                    {label:'司机名称',prop:'driverName',align:'center'},
                  {label:'高德轨迹点个数',prop:'gaodeCounts',align:'center',width:'120'},
                  // {label:'总轨迹点个数',prop:'totalCounts',align:'center',width:'120'},
                  {label:'轨迹完整率百分比%',prop:'percentStr',align:'center',width:'150'},

                  {label: '是否是异常轨迹', prop: 'abnormal', align: 'center', width: '120',
                    formatter: (row) => {
                      if (row.abnormal == 1) {
                        return "是";
                      } else if (row.abnormal == 0) {
                        return "否";
                      } else {
                        return "";
                      }
                    }

                  },

                  {label: '异常分类', prop: 'abnormalLevel', align: 'center', width: '80',
                    formatter: (row) => {
                      if (row.abnormalLevel == 0) {
                        return "正常";
                      } else if (row.abnormalLevel == 1) {
                        return "1";
                      } else if (row.abnormalLevel == 2) {
                        return "2";
                      } else if (row.abnormalLevel == 3) {
                        return "3";
                      } else if (row.abnormalLevel == 4) {
                        return "4";
                      }
                    }
                  },
                  {label: '异常初步判断原因', prop: 'abnormalResult', align: 'center', width: '350'},
                    {label:'追货宝编号',prop:'gpsNo',align:'center',width:'120'},
                    {label:'司机手机',prop:'driverMobile',align:'center',width:'120'},
                    {label:'发车时间',prop:'sendStratTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.sendStratTime == null){
                                return ''
                            }else {
                                return this.$moment(parseInt(row.sendStratTime)).format("YYYY-MM-DD HH:mm:ss")
                            }

                        }
                    },
                    {label:'发车地点',prop:'sendStratAddress',align:'center',width:'120'},
                    {label:'终止时间',prop:'sendEndTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.sendEndTime == null){
                                return ''
                            }else {
                                return this.$moment(parseInt(row.sendEndTime)).format("YYYY-MM-DD HH:mm:ss")
                            }

                        }
                    },
                    {label:'终止地点',prop:'sendEndAddress',align:'center',width:'120'},
                    // {label:'高德轨迹点个数',prop:'gaodeCounts',align:'center',width:'120'},
                    // // {label:'总轨迹点个数',prop:'totalCounts',align:'center',width:'120'},
                    // {label:'轨迹完整率百分比%',prop:'percentStr',align:'center',width:'150'},
                    {label:'创建时间',prop:'createTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.createTime == null){
                                return ''
                            }else {
                                return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                            }

                        }
                    },
                    {label:'创建人',prop:'creator',align:'center'},
                ],
                tableHandles:[  //这是表格和表单之间的一个按钮，我的项目不需要
                    {label:'新增',type:'primary',handle:row=>''}
                ],
                pagination:{ //分页数据
                    pageSize:50,
                    pageNum:1,
                    total:0
                },
                merge:[]
            }
        },

        components:{ //引入组件后注册组件
            cesTable,
            Drawer,
            flightTable,
            otherTable,
        },
        computed:{
            getUploadUrl(){
                let filePath = `${this.$filePath}`;
                return filePath
            },
        },
        mounted(){
          this.dataForms.startTime = this.timeDefault();
          this.dataForms.endtime = this.timeDefault2();
            this.getTableAndForm();

          let parentId = '2_4_2';
          let currentPageButtons = arrayQuery.text(parentId);
          let _this = this;
          _this.currentPageButtons = currentPageButtons;
          //导出按钮权限
          let exportF = currentPageButtons.find(item => {
            return item.menuId == '2_4_2_1';
          });
          if(exportF == undefined){
            _this.exportFlag = false;
          }else {
            _this.exportFlag = true;
          }
        },


        methods:{

          timeDefault() {
            var yesterdsay = new Date(new Date().getTime() - 86400000);//86400000是一天的毫秒数
            var year=yesterdsay.getFullYear();
            var month=yesterdsay.getMonth()+1;
            var date=yesterdsay.getDate();
            var yesterdayDate = `${year}-${month}-${date}`;
            return yesterdayDate;
          },

          timeDefault2() {
            let date1 = new Date();
            let s1 = date1.getFullYear() + "-" + (date1.getMonth()  + 1) + "-" + (date1.getDate() );
            return s1;
          },

          setCellColor:function(e,callback) {
            //e.row：表格那一行的数据；  e.column：表格单元格那一列的信息
            if (e.row.abnormalLevel === 0) {
              callback({background: "#FFFFFF",color: '#646464'});
            } else if (e.row.abnormalLevel === 1){
              callback({background: "#ffc773",color: '#646464'})
            }else if (e.row.abnormalLevel === 2){
              callback({background: "#ffc773",color: '#646464'})
            }else if (e.row.abnormalLevel === 3){
              callback({background: "#ffc773",color: '#646464'})
            }else if (e.row.abnormalLevel === 4){
              callback({background: "#ffc773",color: '#646464'})
            }

          },
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.waybillManagementSearch()
            },
            handleImgClick(imgurl){
                const routeUrl = this.$router.resolve({
                    path: "/publicMapTrack",
                    query: {
                        batchNo: imgurl.imgurl,
                        insertState:"B"
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            //insertState:  A:订单号  B:批次轨迹 C:车辆轨迹
            handleProNoderClick(orderNo){
                const routeUrl = this.$router.resolve({
                    path: "/publicMapTrack",
                    query: {
                        truckNo: orderNo.imgurl,
                        insertState:"C"
                    }
                });
                window.open(routeUrl.href, '_blank');
            },
            //获取表格和表单数据
            async getTableAndForm(){
                this.waybillManagementSearch();
            },
            handleQuery(formName){ //查询
              this.$refs[formName].validate((valid) => {
                if (valid) {
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                const data = new URLSearchParams();
              if(this.rulesForm.AccurateAndUnique_batchNo == '') return;
                data.append('batchNo', this.rulesForm.AccurateAndUnique_batchNo);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/tioNode/getNodePage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
              } else {
                return false;
              }
            });
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            //订单查询
            waybillManagementSearch(){
                this.loading = true;
              let timeDefault;
              let timeDefault2;
              if(this.dataForms.startTime == '' || this.dataForms.startTime == null){
                timeDefault = ''
              }else {
                timeDefault = this.$moment(this.dataForms.startTime).format("YYYY-MM-DD")
              }
              if(this.dataForms.endtime == '' || this.dataForms.endtime == null){
                timeDefault2 = ''
              }else {
                timeDefault2 = this.$moment(this.dataForms.endtime).format("YYYY-MM-DD")
              }
                // let createTimeBegin;
                // let createTimeEnd;
              // if(this.dataForms.startTime == '' || this.dataForms.startTime == null){
              //   createTimeBegin = ''
              // }else {
              //   createTimeBegin = this.$moment(this.dataForms.startTime).format("YYYY-MM-DD")
              // }
              // if(this.dataForms.endtime == '' || this.dataForms.endtime == null){
              //   createTimeEnd = ''
              // }else {
              //   createTimeEnd = this.$moment(this.dataForms.endtime).format("YYYY-MM-DD")
              // }

                const data = new URLSearchParams();
                data.append('batchNo', this.dataForms.batchNo);
                data.append('truckNo', this.dataForms.truckNo);
                data.append('driverId', this.dataForms.driverId);
                data.append('abnormal', this.dataForms.abnormal);
                data.append('driverName', this.dataForms.driverName);
                data.append('startTime', timeDefault);
                data.append('endtime', timeDefault2);
                // data.append('startTime', createTimeBegin);
                // data.append('endtime', createTimeEnd);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/tioNode/getNodePage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.items;
                    this.pagination.total = res.data.total;
                    this.loading = false;
                });
            },
          //导出
          plannedWaybillExport() {
            let timeDefault;
            let timeDefault2;
            // let createTimeBegin;
            // let createTimeEnd;
            if(this.dataForms.startTime == '' || this.dataForms.startTime == null){
              timeDefault = ''
            }else {
              timeDefault = this.$moment(this.dataForms.startTime).format("YYYY-MM-DD")
            }
            if(this.dataForms.endtime == '' || this.dataForms.endtime == null){
              timeDefault2 = ''
            }else {
              timeDefault2 = this.$moment(this.dataForms.endtime).format("YYYY-MM-DD")
            }
            const data = new URLSearchParams();
            data.append('orderNo', this.dataForms.orderNo);
            data.append('batchNo', this.dataForms.batchNo);
            data.append('driverId', this.dataForms.driverId);
            data.append('driverName', this.dataForms.driverName);
            data.append('startTime', timeDefault);
            data.append('endtime', timeDefault2);
            data.append('abnormal', this.dataForms.abnormal);
            data.append('abnormalResult', this.dataForms.abnormalResult);
            data.append('abnormalLevel', this.dataForms.abnormalLevel);
            // data.append('startTime', createTimeBegin);
            // data.append('endtime', createTimeEnd);
            data.append('pageSize', this.pagination.pageSize);
            data.append('pageNo', this.pagination.pageNum);
            var loading = this.$loading({
              lock: true,
              text: '导出中，请稍后',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
            });
            axios({
              method: 'post',
              url: `${this.$apiPath}/api/urban/tioNode/checkTioNodeExportCount`,
              data: data,
            }).then(res => {
              console.log(res.data);
              if (res.data.success == true) {
                axios({
                  method: 'post',
                  url: `${this.$apiPath}/api/urban/tioNode/exportTioNodeExcel`,
                  data: data,
                  responseType: "arraybuffer",
                }).then((res) => {
                  let url = window.URL.createObjectURL(new Blob([res.data], {type: '.xlsx'}));
                  console.log(res.data);
                  let a = document.createElement('a');
                  a.style.display = 'none';
                  a.href = url;
                  a.setAttribute('download', `车次轨迹报表.xlsx`);
                  document.body.appendChild(a);
                  a.click();
                  url = window.URL.revokeObjectURL(url);
                  document.body.removeChild(a)
                  loading.close();
                }).catch(error => {
                  this.$message.error('导出失败')
                  loading.close();
                });
              } else {
                this.$alert(res.data.msg, '提示', {
                  confirmButtonText: '确定',
                });
                loading.close();
              }
            });
          },


            waybillManagementSearch_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.waybillManagementSearch();
            },
            handleClose(done) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.timer = setTimeout(() => {
                    // 动画关闭需要一定的时间
                    setTimeout(() => {
                        this.loading = false;
                    }, 400);
                    this.waybillManagementSearch_Multiple();
                    // 动画关闭需要一定的时间
                    this.dialog = false;
                }, 2000);
            },
            cancelForm() {
                this.loading = false;
                this.dialog = false;
                clearTimeout(this.timer);
            }
        }

    }
</script>
<style>
.tableMain .ces-table-page {
  height: 100%;
}
.tableMain .ces-table {
  height: 100%;
}
.tableMain .el-table th {
  padding: 0;
  height: 35px;
}
.tableMain .el-table td {
  padding: 0;
  height: 35px;
}
.waybill_add .ces-table {
  height: 200px;
}
.waybill_add .el-table th {
  padding: 0;
  height: 30px;
}
.waybill_add .el-table td {
  padding: 0;
  height: 30px;
}
.materialTableData .ces-table {
  height: 200px;
}
.materialTableData .el-table th {
  padding: 0;
  height: 30px;
}
.materialTableData .el-table td {
  padding: 0;
  height: 30px;
}
.materialTableData .el-input__inner {
  height: 30px;
}
</style>
<style scoped>
.boxbutton {
  width: 100%;
  height: 40px;
}
.tableMain {
  width: 100%;
  height: 82%;
}
</style>
